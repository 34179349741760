// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-examples-js": () => import("./../../src/pages/more-examples.js" /* webpackChunkName: "component---src-pages-more-examples-js" */),
  "component---src-pages-try-it-now-js": () => import("./../../src/pages/try-it-now.js" /* webpackChunkName: "component---src-pages-try-it-now-js" */)
}

